function App() {
  return (
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
          <span className="fs-4">Algorand Developer Portal</span>
        </a>
      </header>

      <main>
        <h1>Under construction</h1>
      </main>
      <footer className="pt-5 my-5 text-muted border-top">
        Created by the <a href="https://twitter.com/algoranddotdev" target="_blank" rel="noreferrer">Algorand Developer</a>.
      </footer>
    </div>
  );
}

export {App as default};
